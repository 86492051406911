@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&family=Syne:wght@400;500;600;700&family=IBM+Plex+Sans:wght@400;700&&display=swap')

@font-face
	font-family: 'Gambarino-Regular'
	src: url('./fonts/Gambarino-Regular.woff2') format('woff2'), url('./fonts/Gambarino-Regular.woff') format('woff'), url('./fonts/Gambarino-Regular.ttf') format('truetype')
	font-weight: 400
	font-display: swap
	font-style: normal

@font-face
	font-weight: 400
	font-style: normal
	font-display: swap
	font-family: 'Switzer-Regular'
	src: url('./fonts/Switzer-Regular.woff2') format('woff2'), url('./fonts/Switzer-Regular.woff') format('woff'), url('./fonts/Switzer-Regular.ttf') format('truetype')

@font-face
	font-weight: 600
	font-style: normal
	font-display: swap
	font-family: 'Switzer-Semibold'
	src: url('./fonts/Switzer-Semibold.woff2') format('woff2'), url('./fonts/Switzer-Semibold.woff') format('woff'), url('./fonts/Switzer-Semibold.ttf') format('truetype')

$gutter-in-between: 120px
$header-margin-bottom: 60px
$listing-vertical-gutter: 50px

$gray-background: #f5f5f5
$color-default: #5A5A5A
$color-green: #5b9600
$color-blue: #0C5FDC
$color-red: #EC2339

@mixin font-syne
	font-family: 'Syne', sans-serif
@mixin font-jakarta
	font-family: 'Plus Jakarta Sans', sans-serif
@mixin font-ibm
	font-family: 'IBM Plex Sans', sans-serif

@mixin font-gambarino
	font-family: 'Gambarino-Regular'
@mixin font-switzer
	font-family: 'Switzer-Regular'
@mixin font-switzer-600
	font-family: 'Switzer-Semibold'

@mixin reset-a
	text-decoration: none

@mixin reset-ul
	margin: 0
	padding: 0
	list-style-type: none

@mixin flex-normal-between
	display: flex
	align-items: normal
	justify-content: space-between

@mixin flex-center
	display: flex
	align-items: center
	justify-content: center

@mixin flex-center-start
	display: flex
	align-items: center
	justify-content: flex-start

@mixin flex-end-start
	display: flex
	align-items: flex-end
	justify-content: flex-start

@mixin flex-start-between
	display: flex
	align-items: flex-start
	justify-content: space-between

@mixin flex-center-between
	display: flex
	align-items: center
	justify-content: space-between

@mixin flex-end-between
	display: flex
	align-items: flex-end
	justify-content: space-between

@mixin gradient-blue
	background: linear-gradient(90deg, #0C5FDC 0%, #4891FF 100%)

@mixin main-headline
	color: #000
	font-size: 48px
	margin: 0 0 24px
	font-weight: 400
	line-height: 1.16
	@include font-gambarino

	@media (max-width: 999px)
		font-size: 28px
	@media (max-width: 767px)
		font-size: 24px

	&.size-s
		font-size: 32px

@mixin secondary-headline
	color: #000
	font-size: 20px
	margin: 0 0 8px
	line-height: 1.4
	@include font-switzer-600

body
	margin: 0
	color: $color-default
	font-size: 18px
	line-height: 1.5
	@include font-switzer

img, video
	max-width: 100%
	vertical-align: middle

p
	margin: 0 0 30px

input, textarea
	line-height: 1.5
	@include font-jakarta
	vertical-align: middle

.random-button
	cursor: pointer
	display: inline-block

.text-button
	color: $color-blue

.button
	color: #fff
	border: 0 none
	font-size: 16px
	cursor: pointer
	@include reset-a
	font-weight: 700
	padding: 12px 32px
	border-radius: 50px
	white-space: nowrap
	display: inline-block
	@include font-jakarta
	@include gradient-blue

	&:hover
		background: $color-blue

	&.size-s
		font-size: 13px
		padding: 12px 24px

	&.bordered
		color: #3986E9
		padding: 10px 30px
		background: transparent
		border: 2px solid $color-blue

		&:hover
			color: #fff
			background: $color-blue

	&.height-l
		padding: 23px 32px

	&.bullet
		padding: 0
		width: 50px
		height: 50px
		@include flex-center

	&.full
		width: 100%
		box-sizing: border-box

	&.gray
		color: #000
		background: #F0F0F0

		&:hover
			background: #e0e0e0

	&.is-white
		color: #000
		background: #fff

	&.no-shadow
		box-shadow: none

#root
	overflow: hidden

.site-content
	width: 1148px
	margin: 0 auto
	max-width: 96%
	&.full
		max-width: 100%
		margin: 0px

	&.services
		p
			margin-bottom: 15px

		.on-sale
			color: #FA391E
			font-family: Syne
			font-size: 14px
			font-style: normal
			font-weight: 600
			line-height: 1.4
			text-transform: uppercase
			margin-bottom: 15px

		.spot-light
			font-family: Syne
			text-align: center
			line-height: 1.4

			&.blue
				color: #000
				font-size: 20px
				font-weight: 700
				display: inline-block
				padding: 8px 16px
				border-radius: 58px
				background: #FFF
				box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.20)

				span
					color: #000
					font-size: 14px

			&.standard
				color: $color-blue
				font-size: 14px
				font-weight: 600
				text-transform: uppercase
				display: block
				margin-bottom: 10px
				text-align: left

		.our-mission
			position: relative

			.free-consultation.button
				display: block
				text-align: center
				margin-top: 20px

				a
					text-decoration: none
					color: #FFFFFF

			button.play-btn
				position: absolute
				z-index: 100
				bottom: 50px
				left: 50%
				margin-left: 50px
				border-radius: 50px
				background: linear-gradient(0deg, #0C5FDC 0%, #73BAFB 100%)
				box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.20)
				color: #fff
				text-align: center
				font-family: Syne
				font-size: 20px
				font-style: normal
				font-weight: 700
				line-height: 1.4
				padding: 15px 25px
				display: flex
				align-items: center
				border: none
				cursor: pointer

				svg
					margin-right: 10px

			&.flipped
				border-radius: 24px
				background: #F3F9FF
				padding-right: 40px

				img
					border-top-right-radius: 0px
					border-bottom-right-radius: 0px
					box-shadow: none

		.price-list
			color: rgba(0, 0, 0, 0.69)
			display: flex
			flex-direction: column

			&.combos
				display: block

				+ .free-consultation.button
					margin-top: 0

			.line
				@include flex-center-start
				margin-bottom: 5px

				&.combo-list
					flex-wrap: wrap
					.combo
						width: 170px
						margin-bottom: 10px
						@include flex-center-start
						vertical-align: top

				.icon
					background: linear-gradient(0deg, #0C5FDC 0%, #73BAFB 100%)
					border-radius: 50%
					width: 45px
					height: 45px
					margin-right: 15px

					svg
						padding: 10px
					img
						margin: 0

				.column
					display: flex
					flex-direction: column
					align-items: flex-start

				.price-tag
					font-weight: 600
					text-transform: capitalize

				.space
					font-size: 14px
					line-height: 1.4
					text-transform: capitalize

		.aerial-photos
			.first-line
				display: flex
				align-items: center
				margin-bottom: 25px

				h2
					display: inline-block
					margin: 0px 15px 0px 0px

			.price-list
				.line
					.icon
						background: none
						width: 24px
						height: 24px

						svg
							padding: 0px
				.price-tag
					font-weight: 400 !important

		.virtual-staging
			.first-line
				display: flex
				align-items: center
				margin-bottom: 25px

				h2
					display: inline-block
					margin: 0px 15px 0px 0px

		.twilight-dusk-images
			.first-line
				display: flex
				align-items: center
				margin-bottom: 25px

				h2
					display: inline-block
					margin: 0px 15px 0px 0px

			.price-list
				.line
					.icon
						background: none
						width: 24px
						height: 24px

						svg
							padding: 0px
				.price-tag
					font-weight: 400 !important
		.virtual-tour
			.price-list
				flex-direction: row

				.left
					width: 50%
				.right
					width: 50%
				.line
					.icon
						background: none
						width: 24px
						height: 24px

						img
							width: 100%
				.price-tag
					font-weight: 400 !important

		.contact-us-box
			color: rgba(0, 0, 0, 0.69)
			font-family: Plus Jakarta Sans
			font-size: 16px
			font-style: normal
			font-weight: 400
			line-height: 1.5
			border-radius: 24px
			background: #F3F9FF

			.info-box
				border-radius: 24px
				//background: url("/img/contact-box-cover.png") right bottom no-repeat
				width: auto

				h3
					font-size: 36px
					line-height: 1.3
					text-transform: capitalize
				figure
					background: #FFF
					border-radius: 50%
					width: 40px
					height: 40px

.left-sidebar
	@include flex-start-between

	aside
		width: 26%
	main
		width: 66%
.header
	top: 0
	z-index: 50
	position: sticky
	background: #fff
	margin-bottom: $header-margin-bottom

	&.header-home, &.header-new
		nav
			a
				color: #fff
			.has-submenu
				ul
					background: transparent

	&.header-about, &.header-services, &.header-terms, &.header-privacy
		background: #F3F9FF

	&-inner
		height: 70px
		@include flex-center-between

		.burger-menu 
			display: none
		.mobile-menu
			display: none
		.close-menu
			display: none
		
	&-logo
		margin: 0
		line-height: 1
		font-size: 36px
		@include font-syne
		a
			color: #000
			display: block
			@include reset-a

			img
				height: 36px
				display: block
	a
		@include reset-a

	nav
		width: 40%
		display: flex

		a
			color: #000
			display: inline-block
			margin: 0px 30px 0px 0px
			@include font-switzer-600

			&:last-child 
				margin: 0px

		.has-submenu
			position: relative
			ul
				left: 0
				top: 100%
				display: none
				background: #fff
				padding: 5px 10px
				position: absolute
				border-radius: 5px
				margin: 0 0 0 -10px
				list-style-type: none
			&:hover
				ul
					display: block

	.logo-white
		display: none

	.signin
		width: 30% !important
		text-align: right

		.button
			+ .button
				margin-left: 10px

	.free-consultation
		margin-left: 10px

		a
			color: #FFF
			text-decoration: none


.header-home, .header-new
	position: fixed
	background: transparent
	width: 100%

	.burger-menu
		svg
			path
				fill: #fff

	.logo-white
		display: block
	.logo-dark
		display: none

	&.header-onscroll
		background: #fff

		.burger-menu
			svg
				path
					fill: #000

		.header-inner 
			nav 
				a
					color: #000
				ul
					background: #fff
		.logo-white
			display: none
		.logo-dark
			display: block

.shaking-btn
	animation: shaking 1.5s infinite

@keyframes shaking
	0% 
		transform: translateX(-10px)
	20% 
		transform: translateX(-5px)
	50% 
		transform: translateX(-5px)
	70% 
		transform: translateX(-5px)
	80% 
		transform: translateX(10px)
	90% 
		transform: translateX(-10px)


.header-new
	nav, .signin, .burger-menu
		display: none !important
	.free-consultation
		display: block !important

.footer
	color: #fff
	margin-top: $gutter-in-between
	background: #E2EFFF

	a
		color: #000
		@include reset-a

	&-inner
		padding: 32px 0
		@include flex-center-between
	&-logo
		margin: 0
		font-size: 36px
		@include font-syne
		a
			display: block
		img
			height: 36px
			display: block
	nav
		width: 30%

		a
			margin-left: 30px

	.copyright
		padding: 20px 0
		background: #000
		&-inner
			@include flex-center-between
		&-social
			@include reset-ul
			@include flex-center-start
			li
				margin-left: 30px
	.remax-logo 
		width: 170px
		margin: 0px

		@media (max-width: 767px)
			margin: 20px auto 0px

.head-bar
	margin-bottom: 30px
	@include flex-center-between
	h2
		padding-right: 20px
		margin-bottom: 0 !important
	&-buttons
		@include flex-center
		> *
			margin-left: 16px
.heart-note
	max-width: 800px
	margin: 0px auto 50px

	h2
		color: #000000
		font-size: 48px
		margin: 0px 0px 50px
		font-weight: 400
		line-height: 1.16
		font-family: "Gambarino-Regular"
	.letter
		font-size: 18px

.report-form
	margin: 30px 0px

.warning
	max-width: 600px
	margin: 0px auto

	.warning-title
		color: red
		font-size: 32px
		margin-bottom: 10px

.home-hero
	color: #fff
	text-align: center
	position: relative
	margin-top: 0px
	//background: #C3E3F1 url(./img/bkg-hero-1.png) center bottom repeat-x
	background: #333
	&.new-version
		margin-bottom: $gutter-in-between
		background: linear-gradient(90deg, #0C5FDC 0%, #4891FF 100%)

		+ .footer
			margin-top: 0
		.home-hero-inner
			padding: 60px 0
	&.new-hero
		h2 
			margin: 30px 0px 
			em
				font-style: normal
				text-decoration: underline
				color: $color-red
	&-bkg1
		min-height: 100vh
		@include flex-center
		//background: url(./img/bkg-hero-2.png) center bottom repeat-x
	&-bkg2
		background:	url(./img/home-hero-2.png) right bottom no-repeat
	&-inner
		z-index: 5
		width: 740px
		margin: 0 auto
		position: relative
		padding: 120px 0

		.new-hero-note 
			margin-bottom: 10px

			&.short
				max-width: 500px
				margin: 0px auto 10px

		.search-options
			width: 600px
			text-align: left
			margin: 0px auto 15px
			display: none

		.sell, .questions
			color: #fff
			cursor: pointer
			text-decoration: none
			margin-right: 30px
			display: inline-block
			min-width: 45px
			text-align: center
			@include font-ibm

			&.active
				font-weight: 700
				line-height: 2.2
				border-bottom: 3px solid #FEBA57

		.search-form
			padding: 30px
			background: rgba(255,255,255,0.4)
			border-top-left-radius: 50px
			border-top-right-radius: 50px
			.search-box
				&-form
					border-radius: 8px 50px 50px 8px
				&-icon,
				&-label
					display: none

			.new-hero-note
				margin-bottom: 0px
			.note
				width: 600px
				max-width: 100%
				margin: 0 auto 20px
				text-decoration: none

		.contact-expert
			background: rgba(255, 255, 255, 0.8)
			border-bottom-left-radius: 50px
			border-bottom-right-radius: 50px
			padding: 40px 50px 30px 50px

			&.new 
				margin-bottom: 30px

			.new-hero-note
				color: #000000
				margin-bottom: 25px

			.details
				display: flex
				margin-bottom: 30px
				color: #5A5A5A
				font-size: 15px

				.details-box
					position: relative
					display: block
					padding: 0px 10px

					&:before
						content:""
						background: url(/img/checked-icon.svg) center center no-repeat
						display: block
						width: 15px
						height: 12px
						position: absolute
						top: -20px
						left: 50%

			@media (max-width: 767px)
				padding: 30px 20px
				
				.details
					flex-direction: column
					.details-box
						margin-bottom: 35px

						&:last-child
							margin-bottom: 0px


	h2
		@include main-headline
		color: #fff
		+ p
			margin-bottom: 20px
	.search-box
		width: 600px
		margin: 0 auto
	video, .video-mask
		top: 0
		left: 0
		z-index: 4
		width: 100%
		height: 100%
		object-fit: cover
		position: absolute
		background: rgba(0,0,0,0.5)
	.google-reviews
		margin-top: 30px
		img
			width: 93px

.search-box
	&-form
		padding: 6px
		background: #fff
		text-align: left
		border-radius: 50px
		border: 1px solid #F5F5F5
		@include flex-center-between
	&-icon
		width: 50px
		height: 50px
		border-radius: 50%
		@include flex-center
		background: rgba(254, 186, 87, 0.29)
		display: flex
	fieldset
		flex-grow: 1
		border: 0 none
		padding: 0 10px
	&-label
		color: #000
		font-size: 12px
		line-height: 1.5
		padding-bottom: 3px
		letter-spacing: 3.3px
		text-transform: uppercase
		display: block
		cursor: pointer
	&-input
		padding: 0
		width: 100%
		outline: none
		border: 0 none
		display: block
		font-size: 15px
		background: transparent

.listing-top
	color: #000
	margin: 30px 0 25px

.listing
	margin-bottom: $gutter-in-between
	&.blog-listing
		position: relative
		> *
			position: relative
		&:before
			top: 50%
			left: 50%
			content: ''
			width: 616px
			height: 1231px
			margin-top: -615px
			margin-left: -50vw
			position: absolute
			background: url(./img/decorator-4.png) top left no-repeat
	h2
		@include main-headline
		max-width: 740px
	article, .article
		width: 32%
		color: $color-default
		text-decoration: none
		margin-top: $listing-vertical-gutter
		&:nth-child(1), &:nth-child(2), &:nth-child(3)
			margin-top: 0
	&-articles
		flex-wrap: wrap
		@include flex-start-between
		&.two-per-row
			article, .article
				width: 49%
				&:nth-child(3)
					margin-top: $listing-vertical-gutter
	&-data
		@include flex-start-between
	&-text
		padding-right: 10px
	&-sqft
		color: #000
		margin-top: 10px
		strong
			@include font-switzer-600
	&-focus
		.button
			margin-bottom: 10px
	figure
		margin: 0 0 20px
		overflow: hidden
		border-radius: 30px
		a
			display: block
		img
			width: 100%
			height: 210px
			object-fit: cover
	h3
		@include secondary-headline
		margin-bottom: 10px
		a
			color: #000
			text-decoration: none
	&-text
		.button
			margin-top: 15px
	.load-more
		margin-top: $listing-vertical-gutter

	&-admin
		margin-top: 15px
		.admin-form-success, .admin-form-error
			margin: 15px 0

	&-admin-submit
		margin-top: 15px
		@include flex-center-between
		.button
			width: 48%

.animated-listing
	.head-bar
		h2
			opacity: 0
			transform: translateX(-100%)
			transition: all 500ms linear
		.button
			opacity: 0
			transform: translateX(100%)
			transition: all 500ms linear
	.listing-articles
		article, .article
			opacity: 0
			transform: translateY(-100px)
			transition: all 500ms linear

			&:first-child
				transform: translateX(-100%) translateY(0)
			&:last-child
				transform: translateX(100%) translateY(0)

	&.is-animated
		.head-bar
			h2, .button
				opacity: 1
				transform: translateX(0)
		.listing-articles
			article, .article
				opacity: 1
				transform: translateX(0) translateY(0)

.property-price
		font-size: 10px
		padding: 10px 6px
		text-align: center
		border-radius: 15px
		border: 1px solid rgba(0, 0, 0, 0.07)
		background: rgba(217, 217, 217, 0.10)

		svg
			margin-bottom: 53x
			display: inline-block

		&.mode-tag
			color: #000
			padding: 8px 16px
			display: inline-block

			> *
				display: inline-block
				vertical-align: middle

			div
				margin: 0 0 0 8px
				font-size: 16px

.property-location
	cursor: pointer
	font-size: 16px
	@include flex-center-start
	.search-box-icon
		width: 28px
		height: 28px
		margin-right: 8px
		svg
			width: 12px
			height: 12px
	a
		color: $color-default
		text-decoration: none
		span
			color: #000
			text-decoration: underline

.article-date
	font-size: 14px
	color: $color-blue
	margin-bottom: 10px
	@include font-switzer-600
	text-transform: uppercase

.simulator
	padding: 40px
	border-radius: 78px
	background: #F3F9FF
	margin-bottom: 50px

	h2
		@include main-headline
		text-align: center

	&-inner
		@include flex-end-between

	&-slider
		width: 58%
		padding: 30px
		background: #fff
		border-radius: 30px
		box-sizing: border-box

	&-selection
		@include main-headline
		margin: 0

	&-info
		margin: 0 0 40px

	&-cta
		margin: 20px 0 0

	&-charts
		width: 38%
		@include flex-end-start

	&-chart
		width: 45%
		margin-right: 5%

	&-value
		color: #000
		font-size: 1.5em
		@include font-gambarino

	&-label
		margin-bottom: 5px

	&-chart-bar
		height: 0
		border-bottom: 3px solid $color-blue

		&.dynamic
			background: $color-green
			border-top-left-radius: 25px
			border-top-right-radius: 25px
			border-bottom: 3px solid $color-green

	.rangeslider
		margin: 0
		.rangeslider__fill
			@include gradient-blue
		.rangeslider__handle
			border: 0 none
			@include gradient-blue
			&:after
				content: none
.customer-contact-button
	display: flex
	align-items: center
	justify-content: space-between
	max-width: 520px
	margin: 0px auto 55px

	p
		margin: 0px

	.free-consultation
		margin-left: 20px
		a
			text-decoration: none
			color: #FFF

.our-mission
	position: relative
	@include flex-center-between
	margin-bottom: $gutter-in-between

	.free-consultation.button
		display: block
		text-align: center
		margin-top: 20px

		a
			text-decoration: none
			color: #FFFFFF

	&:before
		left: 50%
		top: -95px
		width: 97px
		content: ''
		height: 191px
		margin-left: -50vw
		position: absolute
		background: url(./img/decorator-1.png) top left no-repeat
	
	&-text
		width: 48%
		opacity: 0
		z-index: 5
		position: relative
		transform: translateX(-100%)
		transition: all 500ms linear

		&.single-item
			width: 100%
	
	h2
		@include main-headline
	
		+ p
			margin-top: 0
	
	img, video
		width: 48%
		opacity: 0
		aspect-ratio: 1
		object-fit: cover
		border-radius: 24px
		transform: translateX(100%)
		transition: all 500ms linear

		&.gif-image
			aspect-ratio: auto
			scale: 1.3

	&.flipped
		flex-direction: row-reverse

		.our-mission-text
			transform: translateX(100%)
		img, video
			transform: translateX(-100%)

	&.is-animated
		.our-mission-text, img, video
			opacity: 1
			transform: translateX(0%)

.benefits-box
	opacity: 0
	font-size: 16px
	padding: 120px 0
	position: relative
	text-align: center
	background: #F3F9FF
	transform: scale(0.1)
	transition: all 500ms linear
	margin-bottom: 50px
	&:before
		top: 0
		left: 50%
		content: ''
		width: 208px
		height: 345px
		margin-left: -50vw
		position: absolute
		background: url(./img/decorator-2.png) top left no-repeat
	&.is-new
		margin-bottom: 0
	.site-content
		position: relative
	h2
		@include main-headline
		margin-bottom: 56px
	&-list
		@include flex-start-between
		flex-direction: column

		.items-row
			display: flex
			width: 100%
			margin-bottom: 20px
	&-item
		width: 50%
		div
			padding: 0px 40px
	figure
		width: 76px
		height: 76px
		border-radius: 50%
		margin: 0 auto 20px
		@include flex-center
		box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.20)
		background: linear-gradient(0deg, #0C5FDC 0%, #73BAFB 100%)
	h3
		@include secondary-headline

	&.is-animated
		opacity: 1
		transform: scale(1)

.reviews
	padding: 100px 0px
	margin-bottom: 80px
	background: #F3F9FF
	.testimonials-subtitle
		font-size: 30px
		margin: 0px 0px 30px
		text-align: left
		line-height: 1.16
		color: #000000
		font-family: "Gambarino-Regular"

	.section-title
		font-size: 48px
		margin: 0px 0px 24px
		color: #000000
		line-height: 1.16
		font-family: "Gambarino-Regular"
		text-align: left
		margin-bottom: 50px

	.row	
		//@include flex-center-between

		.item
			box-sizing: border-box
			background: #fff
			border-radius: 20px
			text-align: left
			font-size: 12px
			padding: 20px
			//width: 32%

			.review-content
				.first-line
					display: flex
					justify-content: space-between

					.review-details
						color: #000000
						display: flex
						justify-content: space-between

						.author-name
							font-family: "Switzer-Semibold"
							font-size: 14px

					.stars
						margin-bottom: 10px
						.star
							width: 20px
							height: 20px
							display: inline-block
							background: url(img/golden-star.svg) top center no-repeat

				.review-body 
					min-height: 120px

	.slick-slide
		> *
			margin-right: 15px

			@media (max-width: 479px)
				margin: 0 5px

.values-box
	text-align: center
	margin-bottom: $gutter-in-between
	h2
		@include main-headline
		margin-bottom: 60px
	&-list
		@include flex-normal-between
	&-item
		width: 32%
		padding: 30px
		border-radius: 30px
		box-sizing: border-box
		background: #F0F0F0

		> div
			margin: 0 auto
			max-width: 240px
	figure
		width: 76px
		height: 76px
		background: #fff
		border-radius: 50%
		margin: 0 auto 20px
		@include flex-center
	h3
		@include secondary-headline

.team-box
	text-align: center
	padding: 60px 0 80px
	margin-bottom: $gutter-in-between
	background: linear-gradient(180deg, #CAE7F4 0%, #A5D0E3 100%)
	h2
		@include main-headline
		margin-bottom: 130px
	&-list
		@include flex-center
	&-item
		width: 290px
		margin: 0 22px
		border-radius: 78px
		background: $gray-background
		padding: 0 30px 50px
		box-sizing: border-box
		box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.20)
	figure
		margin: -67px 0 25px
		img
			width: 134px
			height: 134px
			object-fit: cover
			border-radius: 50%
	h3, h4
		@include secondary-headline
	h4
		color: $color-blue

.trends-box
	opacity: 0
	font-size: 16px
	text-align: center
	transform: scale(0.1)
	transition: all 500ms linear
	margin-bottom: $gutter-in-between

	h2
		@include main-headline
		margin-bottom: 56px
	&-items
		@include flex-start-between
		flex-direction: column

		.items-row
			display: flex
			margin-bottom: 20px
	&-item
		width: 50%
		div
			padding: 0px 40px
	figure
		margin: 0 0 10px
		img
			width: 100px
	h3
		@include secondary-headline

	&.is-animated
		opacity: 1
		transform: scale(1)

@keyframes example
	from
		transform: rotate(45deg)
	to
		transform: rotate(145deg)

.cta-box
	opacity: 0
	z-index: 20
	position: relative
	background: #F3F9FF
	border-radius: 24px
	transform: scale(0.1)
	transition: all 500ms linear
	margin-bottom: $gutter-in-between
	&-inner
		border-radius: 24px
		position: relative
		padding: 95px 0 110px 70px
		background: url(./img/cta-bkg.png) right bottom no-repeat
		&:before
			top: 0
			left: 0
			content: ''
			width: 216px
			height: 261px
			position: absolute
			border-top-left-radius: 24px
			background: url(./img/decorator-3.png) top left no-repeat
		&:after
			content: ''
			width: 112px
			height: 90px
			right: -56px
			bottom: -30px
			position: absolute
			background: url(./img/decorator-5.png) top left no-repeat
		> *
			position: relative
	h2
		@include main-headline
		margin-bottom: 10px
	&-info
		font-size: 18px
		margin: 0 0 25px
		max-width: 450px
	.search-box
		width: 600px

	&.is-animated
		opacity: 1
		transform: scale(1)

.main-article
	margin-bottom: 95px
	@include flex-center-between
	figure
		margin: 0
		width: 48%
		overflow: hidden
		border-radius: 30px
	.article-data
		width: 48%
		h2
			@include main-headline
			margin-bottom: 10px
			font-size: 40px
		.button
			margin-top: 30px

.auth-screen
	text-align: center
	background: #F3F9FF url(./img/bkg-auth.png) center bottom repeat-x

	&.move-up
		margin-top: -$header-margin-bottom

	&-bkg1
		//background:	url(./img/home-hero-1.png) left bottom no-repeat
	&-bkg2
		min-height: 90vh
		padding: 24px
		box-sizing: border-box
		//background:	url(./img/home-hero-2.png) right bottom no-repeat

	.header-logo
		margin-bottom: 30px
		display: inline-block

	h2
		@include main-headline

	&-info
		color: #000
		margin: 0 auto
		max-width: 730px

		em
			font-size: 1.2em
			font-weight: 700
			color: $color-red
			font-style: normal
			font-family: 'Arial'

	+ .footer
		margin-top: 0

.auth-box
	padding: 30px
	max-width: 464px
	border-radius: 24px
	margin: 45px auto 0
	box-sizing: border-box
	border: 1px solid #F5F5F5
	background: #fff

	&.for-dashboard
		margin: 0
		width: 32%

		@media (max-width: 767px)
			width: 100%

		+ div
			width: 32%

	&.bkg-white
		background: #fff
	&.w-auto
		max-width: none
	&.p-l
		padding: 45px
	&.m-t-zero
		margin-top: 0
	&.compacted
		margin: 0
		padding: 10px
		box-shadow: none
		border-radius: 0
		.formfield + .button
			margin-top: 10px

	h3
		@include main-headline

	.sub-title
		margin: 0 0 30px

	.formfield
		+ .formfield
			margin-top: 15px
		+ .button
			margin-top: 35px

	label
		display: block
		font-size: 12px
		font-weight: 600
		margin-bottom: 5px

	select,
	input[type=tel],
	input[type=text],
	input[type=date],
	input[type=email],
	input[type=number],
	input[type=password],
	textarea
		width: 100%
		height: 46px
		padding: 0 15px
		font-size: 16px
		border-radius: 6px
		box-sizing: border-box
		border: 1px solid rgba(0, 0, 0, 0.07)
		background: rgba(217, 217, 217, 0.10)

		&.white-bkg
			background: #fff

	textarea
		height: 150px
		padding: 10px 15px

	.forgot
		text-align: right
		margin: 5px 0 45px

		a
			color: #000
			opacity: 0.6

	&-footer
		margin-top: 15px

		a
			opacity: 0.6
			color: $color-blue

.contact-page
	overflow: hidden
	min-height: 90vh
	background: #eee
	padding: 85px 20px
	box-sizing: border-box
	margin-top: -$header-margin-bottom
	background: #F3F9FF url(./img/bkg-auth.png) center bottom repeat-x

	+ .footer
		margin-top: 0	

.contact-content
	@include flex-center-between	

	.auth-box
		margin: 0
		width: 50%
		max-width: none
		position: relative
	.info-box
		width: 48%
		background: #C3E3F1
		border-radius: 30px
		box-sizing: border-box

.info-box
	padding: 60px

	h3
		@include main-headline
		&.centered
			text-align: center

	ul
		padding: 0
		list-style-type: none

	&-bullets
		margin: 0
		figure
			width: 76px
			height: 76px
			margin: 0 12px 0 0
			border-radius: 50%
			background: linear-gradient(180deg, #CAE7F4 0%, #A5D0E3 100%)
			@include flex-center
		li
			color: #000
			margin-bottom: 16px
			@include flex-center-start

			> div
				> div 
					+ div
						margin-top: 5px
						font-weight: 700
						@include font-syne

						a
							text-decoration: none
							color: #000
						span
							opacity: 0.6
							margin-left: 5px

	&-icons
		margin: 0
		@include flex-center-start

		li
			margin: 0 30px 0 0

		svg
			vertical-align: middle

.page-title
	padding: 20px
	min-height: 240px
	position: relative
	text-align: center
	@include flex-center
	margin-bottom: 100px
	box-sizing: border-box
	border-radius: 0px 0px 30px 30px
	margin-top: -$header-margin-bottom
	background: #F3F9FF

	&:before
		top: 0
		left: 0
		right: 0
		bottom: 0
		position: absolute
		background: rgba(0,0,0,0.5)

	h2
		@include main-headline
		margin: 0

.admin-page-title
	@include main-headline
	margin-bottom: 40px

.admin-page-head
	margin-bottom: 40px
	@include flex-center-between

	.admin-page-title
		margin-bottom: 0

.admin-section-title
	@include secondary-headline
	margin-bottom: 20px

.admin-top-search
	display: flex
	position: relative
	align-items: center
	input
		margin: 0
		height: 48px
		width: 150px
		padding: 5px 10px
		box-sizing: border-box
		border-top-left-radius: 50px
		border-bottom-left-radius: 50px
		border: 1px solid $color-blue

		+ .button
			border-top-left-radius: 0
			border-bottom-left-radius: 0

.top-search-results
	right: 0
	top: 100%
	min-width: 100%
	font-size: 14px
	background: #fff
	text-align: right
	position: absolute
	white-space: nowrap
	border-radius: 24px
	box-sizing: border-box
	border: 1px solid $color-blue

	> *
		padding: 8px 12px

	.top-search-result
		cursor: pointer
	.text-button
		margin-left: 10px

.static-page
	p
		margin: 15px 0
	h3
		@include main-headline
	h4
		@include main-headline
		margin: 40px 0 20px
		font-size: 24px
	ul, ol
		padding: 0
		margin: 20px 0
		list-style-position: inside

.testimonials
	padding: 100px 0
	background: $gray-background
	margin-top: -$gutter-in-between
	margin-bottom: $gutter-in-between
	h2
		@include main-headline
	&-list
		@include flex-start-between
	&-item
		width: 48.5%
		padding: 16px
		display: flex
		color: #00193D
		background: #FCFCFD
		border-radius: 30px
		box-sizing: border-box
		border: 2px solid #F4F5F6
		justify-content: space-between
	figure
		margin: 0
		width: 35%
		position: relative

		img
			width: 100%
			height: 100%
			object-fit: cover
			position: relative
			border-radius: 20px
	&-item-text
		width: 61%
	&-item-top
		margin-bottom: 5px
		@include flex-center-between

		address
			color: #FEBA57
			font-style: normal
		svg
			margin-left: 2px
			vertical-align: middle

.tall-page
	overflow: hidden
	min-height: 90vh
	padding: 85px 20px
	box-sizing: border-box
	margin-top: -$header-margin-bottom

	+ .footer
		margin-top: 0

.onboarding
	&-steps
		padding: 0
		width: 900px
		margin: 0 auto 20px
		list-style-type: none
		@include flex-center-between
	&-step
		color: #000
		font-weight: 800
		position: relative
		display: inline-block

		&:after
			top: 42px
			left: 90%
			content: ''
			width: 182px
			position: absolute
			border-top: 3px dashed $color-blue
		&:last-child
			&:after
				content: none

		svg 
			display: block
			+ svg
				top: 50%
				left: 50%
				position: absolute
				transform: translate3d(-50%, -50%, 0)

		span
			top: 50%
			left: 50%
			opacity: 0.6
			margin-top: -8px
			margin-left: 2px
			position: absolute
			transform: translate3d(-50%, -50%, 0)

			&.is-active
				opacity: 1
				color: #fff

	&-screen
		@include flex-center-between

	&-info
		z-index: 5
		width: 460px
		position: relative
		border-radius: 30px
		background: $gray-background
		box-sizing: border-box
		padding: 60px 30px 20px
		box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.20)

		h2
			@include main-headline
			&.size-s
				margin-bottom: 45px
		p
			margin: 0 0 70px

		&-buttons
			@include flex-center-between

			> *:last-child
				@include flex-center
				margin-left: 10px
				flex-grow: 1

	&-media
		width: 60%
		position: relative
		padding: 90px 0 60px

		&-bkg
			top: 0
			bottom: 0
			left: -100px
			right: -9999px
			position: absolute
			border-radius: 30px 0px 0px 30px
			background: linear-gradient(180deg, #CAE7F4 0%, #A5D0E3 100%)

		img
			position: relative

.search-filters
	&.auth-box
		label
			font-size: 15px
			font-weight: normal
	h2
		@include main-headline
		&.size-s
			margin-bottom: 15px
	h3
		@include secondary-headline
		font-size: 16px
	.filters-separator
		font-size: 0
		max-width: 100%
		line-height: 2px
		overflow: hidden
		margin-bottom: 25px
		svg
			vertical-align: middle
	.filter-group
		text-align: left
		font-weight: 500
		padding-bottom: 25px
		color: $color-default
		&:last-child
			padding-bottom: 0
	ul
		@include reset-ul
		li
			margin-bottom: 10px
			&:last-child
				margin-bottom: 0
		&.two-cols
			margin-top: 20px
			overflow: hidden
			li
				width: 48%
				float: left
				margin-right: 2%
	input
		&[type=checkbox]
			vertical-align: 0
			margin: 0 10px 0 2px
			transform: scale(1.25)
		&[type=number]
			width: 100%
			height: 46px
			padding: 0 15px
			font-size: 16px
			border-radius: 6px
			box-sizing: border-box
			border: 1px solid rgba(0, 0, 0, 0.07)
			background: rgba(217, 217, 217, 0.10)

.article-header
	text-align: center
	background: $gray-background
	margin-top: -$header-margin-bottom
	padding: $header-margin-bottom 0

	h2
		@include main-headline
	.article-date
		margin-bottom: 0

.article-image
	position: relative
	margin-bottom: 65px

	&:before
		top: 0
		left: 0
		right: 0
		height: 50%
		content: ''
		position: absolute
		background: $gray-background

	.site-content
		position: relative
		&:after
			content: ''
			width: 112px
			height: 90px
			left: -56px
			bottom: -45px
			position: absolute
			background: url(./img/decorator-5.png) top left no-repeat

	img
		width: 100%
		height: 446px
		object-fit: cover
		position: relative
		border-radius: 30px

.article-columns
	margin-bottom: 70px
	//@include flex-start-between

	aside
		div
			&:first-child
				color: #000
				font-size: 12px
				font-weight: 600
				margin-bottom: 16px
				letter-spacing: 3.3px
				text-transform: uppercase
			&:last-child
				@include flex-start-between
		span
			width: 44px
			height: 44px
			cursor: pointer
			line-height: 44px
			text-align: center
			margin-right: 16px
			border-radius: 50%
			background: #F0F0F0
			@include flex-center
			svg
				display: block

	main
		width: 75%

	h3, h4, h5
		@include main-headline
		margin-bottom: 15px
		font-size: 40px
	h4
		font-size: 24px
	h5
		font-size: 16px
	blockquote
		color: #000
		font-size: 24px
		margin: 0 0 30px
		padding: 0 0 0 24px
		border-left: 4px solid $color-blue

.article-gallery
	@include flex-start-between
	margin-bottom: 30px

	img
		width: 49%
		border-radius: 30px

.property-top
	margin-bottom: 50px
	@include flex-start-between

	.property-slider
		width: 100%
		position: relative

		.prev-button, .next-button
			right: 88px
			bottom: 24px
			position: absolute
		.next-button
			right: 24px

		img
			width: 100%
			height: 548px
			object-fit: cover
			border-radius: 30px

form.status-success
	.formfield, button
		display: none

.property-contact
	margin: 0
	width: 32%
	padding: 16px
	border-radius: 16px
	box-sizing: border-box
	background: #C3E3F1

	.property-agent
		padding: 12px
		border-radius: 6px
		margin-bottom: 20px
		@include flex-center-start
		background: rgba(255, 255, 255, 0.32)

		img
			width: 52px
			aspect-ratio: 1
			object-fit: cover
			margin: 0 16px 0 0
			border-radius: 50%

		&-name
			color: #000
			font-weight: 600
		&-company
			font-size: 14px

	input, textarea
		background: #F5F5F5 !important
	textarea
		height: 92px
	.formfield + .button
		margin-top: 24px

.property-page
	@include flex-start-between
	margin-bottom: $header-margin-bottom

.property-main
	width: 66%

.property-super
	@include flex-center-between

.property-super, .property-main
	.property-price
		margin-bottom: 16px

	.property-title
		margin-bottom: 16px
		@include flex-center-start

		h2
			@include main-headline
			margin: 0 40px 0 0
			font-size: 40px
		.button
			margin-right: 16px

	.property-location
		margin-bottom: 25px

	.property-description
		margin-bottom: 25px
		padding-bottom: 25px
		border-bottom: 1px solid #EDEDED

	.property-size
		color: #000
		margin-bottom: 25px
		padding-bottom: 25px
		border-bottom: 1px solid #EDEDED
		@include flex-center-start

		> div
			margin-right: 48px
			@include flex-center-start

			svg
				margin-right: 10px

.property-expander
	margin-bottom: 25px
	padding-bottom: 25px
	border-bottom: 1px solid #EDEDED

	&-head
		cursor: pointer
		@include flex-center-between

		> div
			@include flex-center-start

			span
				@include main-headline
				margin: 0 0 0 10px !important

	&-content
		padding-top: 15px

.admin-panel
	@include flex-normal-between

	aside
		color: #fff
		width: 285px
		flex-shrink: 0
		min-height: 100vh
		box-sizing: border-box
		padding: 30px 20px 30px 0
		border-radius: 0px 30px 30px 0px
		background: linear-gradient(94deg, #414141 0%, #111 101.87%), #D9D9D9

		.header-logo
			margin: 0 0 20px 20px 

		form
			position: relative
			border-radius: 50px
			background: #292929
			margin: 0 0 20px 20px
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25)

			input
				color: #fff
				width: 100%
				height: 60px
				border: 0 none
				font-size: 16px
				border-radius: 50px
				padding: 0 70px 0 30px
				box-sizing: border-box
				background: transparent

			button
				top: 5px
				right: 5px
				position: absolute

		ul
			margin: 0
			padding: 0
			list-style-type: none

			li
				margin-bottom: 15px
				
				a
					padding: 12px 0 12px 70px
					@include flex-center-start
					border-radius: 0px 50px 50px 0px

					svg
						margin-right: 10px

					&.active
						background: linear-gradient(275deg, $color-blue 1.55%, #73BAFB 98.16%)

		a
			color: #fff
			text-decoration: none

	main
		flex-grow: 1
		padding: 20px
		position: relative

		header
			top: 20px
			right: 20px
			position: absolute
			@include flex-center

			svg
				margin: 0 24px

			img
				width: 36px
				height: 36px
				object-fit: cover
				border-radius: 50%
				border: 4px solid #F1F1F1

		table
			width: 100%
			color: #00193D
			font-size: 14px
			border-collapse: collapse

			tr
				td, th
					padding: 15px 0
					text-align: left
					border-bottom: 1px solid #E6E8EC

					&.status
						color: #47B603

					&.price
						span
							color: $color-blue
						small
							margin: 0 6px
						svg
							vertical-align: -2px

					&.action-buttons
						> svg
							margin-right: 6px

				th
					padding-top: 0
					color: #777E90
					cursor: pointer
					font-weight: 500

					> svg
						margin-left: 5px

.summary-boxes
	margin-bottom: 40px
	@include flex-center-start

	> div
		padding: 20px
		min-width: 130px
		margin-right: 20px
		border-radius: 30px
		box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.20)
		background: linear-gradient(180deg, #CAE7F4 0%, #A5D0E3 100%)

		> div:first-child
			margin-bottom: 5px
		> div:last-child
			color: #00193D
			font-size: 20px
			font-weight: 600

.widgets
	//@include flex-start-between
	margin-bottom: 40px

	>	div
		//width: 48%
		overflow: auto

		.listing
			//width: 150%
			margin: 0 0 10px

.fake-chart
	td
		height: 25px
		border: 1px solid #E6E8EC

.red-text
	color: $color-red

.text-l
	font-size: 1.2em

.form-error
	color: $color-red
	font-size: 12px
	margin: 10px 0

.form-success
	color: $color-green
	font-size: 12px
	margin: 10px 0

.search-results
	font-size: 12px

	li
		overflow: hidden
		margin: 0 !important
		border-top: 1px solid #ddd
		&:hover
			background: #888
		&:last-child
			border-bottom-left-radius: 50px
			border-bottom-right-radius: 50px

		a
			padding: 10px 30px !important

.admin-form
	input[type=text], textarea
		width: 500px
		height: 40px
		display: block
		padding: 0 15px
		font-size: 14px
		max-width: 100%
		border-radius: 5px
		border: 1px solid $color-default
	textarea
		height: 200px
		padding: 15px

.admin-formfield
	margin-bottom: 30px

	label
		display: block
		font-weight: 600
		margin-bottom: 5px

.admin-form-error
	color: $color-red

.admin-form-success
	color: $color-green

.ck-editor__editable
	min-height: 300px
