$section-margin-bottom-tablet: 45px

@media (max-width: 1199px)
	.site-content
		width: auto
		margin: 0 20px
		max-width: 100%

	.summary-boxes
		flex-wrap: wrap
		justify-content: space-between
		> div
			width: 49%
			margin: 0 0 20px
			box-sizing: border-box

@media (max-width: 999px)
	.header
		margin-bottom: $section-margin-bottom-tablet
		nav, .signin
			width: 35%

		.button
			font-size: 14px
			padding: 10px 20px
	.footer
		margin-top: $section-margin-bottom-tablet

		nav
			width: auto

	.home-hero
		height: auto

		&.new-version
			margin-bottom: $section-margin-bottom-tablet

		h2
			font-size: 50px

	.simulator
		margin-bottom: $section-margin-bottom-tablet

	.our-mission
		margin-bottom: $section-margin-bottom-tablet

		&-text
			opacity: 1
			transform: translateX(0) !important
		
		img, video
			opacity: 1
			transform: translateX(0) !important

	.listing
		margin-bottom: $section-margin-bottom-tablet

	.animated-listing
		.head-bar
			h2, .button
				opacity: 1
				transform: translateX(0)
		.listing-articles
			article, .article
				opacity: 1
				transform: translateX(0) translateY(0) !important

	.benefits-box
		opacity: 1
		padding: 30px
		transform: scale(1)
		margin-bottom: $section-margin-bottom-tablet
	
	.trends-box
		opacity: 1
		padding: 0
		transform: scale(1)
		margin-bottom: $section-margin-bottom-tablet

	.cta-box
		opacity: 1
		transform: scale(1)
		border-radius: 30px
		margin-bottom: $section-margin-bottom-tablet

		&-inner
			padding: 30px
		&-info
			max-width: 300px

	.page-title
		min-height: 200px
		margin-top: -$section-margin-bottom-tablet
		margin-bottom: $section-margin-bottom-tablet

	.values-box
		margin-bottom: $section-margin-bottom-tablet
		&-item
			padding: 20px
		h2
			margin-bottom: calc($section-margin-bottom-tablet / 2)

	.team-box
		padding: $section-margin-bottom-tablet 0
		margin-bottom: $section-margin-bottom-tablet

		h2
			margin-bottom: $section-margin-bottom-tablet * 1.5
		&-item
			width: 30%
			margin: 0 1%
			padding: 0 20px 30px
			box-sizing: border-box

	.testimonials
		padding: $section-margin-bottom-tablet 0
		margin-top: -$section-margin-bottom-tablet
		margin-bottom: $section-margin-bottom-tablet

	.article-header
		padding: $section-margin-bottom-tablet 0
		margin-top: -$section-margin-bottom-tablet

	.article-image
		margin-bottom: $section-margin-bottom-tablet

	.article-columns
		margin-bottom: calc($section-margin-bottom-tablet / 2)

	.main-article
		margin-bottom: $section-margin-bottom-tablet

	.contact-page
		padding: 30px 0
		margin-top: -$section-margin-bottom-tablet
	.auth-box
		&.p-l
			padding: 30px
	.info-box
		padding: 30px
	.contact-content
		display: block
		.auth-box, .info-box
			width: auto
			border-radius: 30px
		.auth-box
			margin-bottom: 30px

	.admin-panel 
		aside
			width: 200px
			ul
				li
					a
						padding-left: 20px
		main
			.scrollable-holder
				overflow: scroll
				width: 100%
			table
				&.scrollable
					width: 180%

	.summary-boxes
		margin-bottom: 30px

	.widgets
		display: block
		margin-bottom: 30px
		> div
			width: auto
			margin-bottom: 30px

	.tall-page
		margin-top: -$section-margin-bottom-tablet
		padding: $section-margin-bottom-tablet 0
		min-height: 80vh

	.onboarding-steps
		width: 600px

		.onboarding-step
			&:after
				width: 80px

	.property-top 
		.property-contact
			padding: 20px
			h3.size-s
				margin-bottom: 20px
			.property-agent
				margin-bottom: 20px

	.property-main
		width: auto
		margin-bottom: $section-margin-bottom-tablet

	.left-sidebar 
		main
			width: 70%

	.site-content.services 
		.contact-us-box 
			.info-box
				background-image: none

@media (max-width: 767px)
	p
		margin-bottom: 20px
	
	.header-inner
		height: auto
		flex-wrap: wrap
		padding: 10px 10px 10px 0px

		.free-consultation
			display: none
		nav
			width: 100%
			padding: 10px 0
			text-align: center
			display: none

		.burger-menu 
			width: 40px
			height: 40px
			display: inline-block

		.mobile-menu
			position: fixed
			background: #fff
			top: 0
			bottom: 0
			right: 0
			left: 0
			display: none

			&.display-block
				display: block

			.mobile-menu-list 
				list-style-type: none
				margin: 0px
				padding: 40px 20px

				li 
					margin-bottom: 20px

					&:nth-child(5)
						margin-bottom: 40px

					a
						color: #000
						display: block
						font-size: 22px

						&.signin-btn
							text-align: center
							color: #fff

							&.margin-top
								margin-top: 30px

		.close-menu
			display: block
			z-index: 100
			position: absolute
			top: 20px
			right: 20px
			cursor: pointer

		.header-logo
			width: auto
			img
				margin: 0 auto
		.signin
			width: 100%
			text-align: center
			display: none

	.footer
		&-logo
			margin-bottom: 20px
			img
				display: inline-block
		&-inner
			display: block
			text-align: center
			a
				margin: 0 10px !important

	.home-hero
		&-inner
			width: 100%
			box-sizing: border-box
			padding: 90px 20px 60px
		h2
			font-size: 32px
			margin-bottom: 20px
		p
			font-size: 15px
		.search-box
			width: 100%
		.search-form
			padding: 20px 25px 25px
			.note
				font-size: 15px
		.google-reviews
			margin-top: 20px

	.head-bar
		display: block
		h2
			padding: 0
			margin: 0 0 20px !important

	.listing
		&-articles
			display: block
		article
			width: auto !important
			margin-bottom: 30px
			figure
				margin-bottom: 10px
				img
					width: 100%
					height: 180px
					object-fit: cover
		.load-more
			margin-top: 30px

	.simulator
		padding: 30px

		&-inner
			display: block
		&-slider
			width: auto
			padding: 20px
			margin-bottom: 0
		&-charts
			width: auto
			min-height: 220px
			justify-content: center
		&-chart
			margin: 0 10px

	.customer-contact-button
		flex-direction: column
		p
			margin-bottom: 10px

	.our-mission
		display: block
		&-text, img, video
			width: 100%
			&:last-child
				margin-top: 30px
		img, video
			border-radius: 30px

	.benefits-box
		font-size: 16px

		h2
			margin-bottom: 10px
		h3
			font-size: 18px
		&-list
			display: block

			.items-row
				flex-direction: column
		&-item
			width: 100%
			margin-top: 30px

			div
				padding: 0px

	.reviews 
		padding: 40px 0 0
		margin-bottom: 40px
		.section-title
			font-size: 16px
			margin-bottom: 20px
		.row
			flex-direction: column
			.item
				width: 100%
				margin-bottom: 30px
	.trends-box
		h2
			margin-bottom: 20px
		&-items
			display: block
			.items-row
				flex-direction: column

		&-item
			width: 100%
			margin-top: 30px

			div
				padding: 0px

	.cta-box
		&-inner
			padding: 20px
			background: none
			&:after
				content: none
		&-info
			max-width: 200px
		.search-box
			width: 100%

	.values-box
		&-list
			display: block
		&-item
			width: 100%
			margin-top: 30px
			> div
				max-width: none

	.team-box
		h2
			margin-bottom: 30px
		&-list
			display: block
		&-item
			width: 100%
			padding: 20px
			margin: 30px 0 0
			border-radius: 30px
			figure
				margin: 0 0 20px

	.testimonials
		.head-bar-buttons
			display: none
		&-list
			display: block
		&-item
			width: 100%
			margin-top: 30px

	.article-header
		padding-bottom: 30px
	.article-image
		margin-bottom: 30px
		img
			height: 300px
	.article-columns
		display: block
		aside
			margin-bottom: 30px
			div
				&:last-child
					justify-content: flex-start
		main
			width: auto
	.article-date
		margin-bottom: 10px

	.main-article
		display: block
		> figure
			width: auto
			margin-bottom: 30px
		.article-data
			width: auto

	.info-box-bullets 
		figure
			display: none

	.admin-panel
		display: block
		aside
			width: 100%
			min-height: 0
			padding: 20px 20px 20px 0
			ul
				li
					&:last-child
						margin-bottom: 0

	.auth-screen
		h2
			font-size: 36px
			line-height: 1.2
			margin-bottom: 30px
		&-bkg1
			background: none
		&-bkg2
			background: none
			padding: 30px 20px

	.onboarding
		&-steps
			width: 320px
		&-step
			svg
				width: 80px
				height: 80px
			&:after
				content: none
		&-info
			padding: 30px
			h2.size-s, p
				margin-bottom: 30px
		&-media
			display: none

	.property-top
		display: block
		.property-slider
			width: 100%
			img
				height: 360px
		.property-contact
			width: auto
			margin-top: 30px

	.property-super, .property-main
		.property-size
			display: block
			margin-bottom: 30px

	.property-page
		display: block
	.property-main, .property-contact
		width: 100%

	.property-expander-head 
		> div 
			span.size-s
				font-size: 18px

	.left-sidebar
		display: block
		.search-filters
			display: none
			width: 100%
		main
			width: 100%

	.site-content.services 
		.our-mission
			&.flipped
				padding: 20px
				border-radius: 30px
				img
					border-radius: 20px
			button.play-btn
				left: 20px
				margin: 0
		.contact-us-box 
			border-radius: 30px
			.info-box 
				border-radius: 30px
				h3
					font-size: 24px

	.heart-note
		h2
			font-size: 32px
			margin-bottom: 20px
		.letter
			font-size: 15px
